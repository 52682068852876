import { useRef, useCallback } from 'react';
import { useTranslation } from "react-i18next";
import { LanguageDisclaimer } from "../language-disclaimer/LanguageDisclaimer";
import { EntitySection } from "./EntitySection/EntitySection";
import { EventsSection } from "./EventsSection/EventsSection";
import { NewsSection } from "./NewsSection/NewsSection";
import { Link } from "react-router-dom";
import "./MainInfo.css";
import { NavigationSection } from "./NavigationSection/NavigationSection";
import { OfficialLinksSection } from "./OfficialLinksSection/OfficialLinksSection";
import { ToDoSection } from "./ToDoSection/ToDoSection";

// Интервалы для аудио (начало-конец в секундах)
const AUDIO_SEGMENTS = [
    { start: 1, end: 3 },
    { start: 7, end: 9 },
    { start: 10, end: 12 },
    { start: 12, end: 14 },
    { start: 20, end: 22 }
];

export const MainInfo: React.FC = () => {
    const { t, i18n } = useTranslation();
    const jabImageRef = useRef<HTMLImageElement>(null);
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const isPlayingRef = useRef(false);

    const playRandomAudioSegment = useCallback(() => {
        if (isPlayingRef.current) return;
        
        const randomSegment = AUDIO_SEGMENTS[Math.floor(Math.random() * AUDIO_SEGMENTS.length)];
        const audio = audioRef.current;
        
        if (!audio) return;
        
        audio.currentTime = randomSegment.start;
        isPlayingRef.current = true;
        
        // Запускаем анимацию сразу, не дожидаясь воспроизведения
        if (jabImageRef.current) {
            jabImageRef.current.classList.add('jab-pressed');
        }
        
        audio.play().catch(e => console.error("Audio play failed:", e));
        
        const duration = (randomSegment.end - randomSegment.start) * 1000;
        setTimeout(() => {
            audio.pause();
            isPlayingRef.current = false;
        }, duration);
    }, []);

    const handleJabClick = useCallback((e: React.MouseEvent<HTMLImageElement>) => {
        e.preventDefault();
        e.stopPropagation();
        playRandomAudioSegment();
        
        // Добавляем класс с анимацией
        if (jabImageRef.current) {
            jabImageRef.current.classList.add('jab-pressed');
            setTimeout(() => {
                jabImageRef.current?.classList.remove('jab-pressed');
            }, 300);
        }
    }, [playRandomAudioSegment]);

    // Dynamic image path based on language
    const teaserImagePath = i18n.language === 'ru' ? "/images/reklamaru.webp" : "/images/reklamaen.webp";
    const teaserImagePath2 = i18n.language === 'ru' ? "/images/disru.webp" : "/images/disen.webp";
    const teaserImagePath3 = i18n.language === 'ru' ? "/images/jab.png" : "/images/jab.png";

    return (
        <section className="main-info">
            <header className="main-info-header-main">
                <h1>Great <span>Limbus</span> Library</h1>
                <p className="main-info-header-description">
                    {t(`MainInfo.headerDescription1`)}<br /><br />
                </p>
                <LanguageDisclaimer />
            </header>
            <div className="rek-image-container">
                <img 
                    src={teaserImagePath2} 
                    className="reklama-image2"
                    onClick={() => window.location.href = "https://discord.gg/BGpmmqknWE"}
                />
                <img 
                    ref={jabImageRef}
                    src={teaserImagePath3} 
                    className="jab-image"
                    onClick={handleJabClick}
                />
                <img 
                    src={teaserImagePath} 
                    className="reklama-image"
                    onClick={() => window.location.href = "https://boosty.to/gll-fun"}
                />
            </div>
            <section className="main-info-left">
                <EntitySection />
                <NavigationSection />
                <ToDoSection />
                <OfficialLinksSection />
            </section>
            <section className="main-info-right">
                <NewsSection />
                <EventsSection />
            </section>
        </section>
    );
};