import React from "react";
import { EntityFullInfoIdentityMain } from "../components/entity-full-info/EntityFullInfoIdentityMain";
import { CommonPageLayout } from "./CommonPageLayout";
import { LoadingPageWrapper } from "./LoadingPageWrapper";
import Analytics from "../components/Analytics"; // Импортируем ваш компонент
import Sweepers from "../components/Sweepers";

export const IdentityPage:React.FC = () => {
   
    return <CommonPageLayout>
        <Sweepers/>
            <Analytics />  {/* Вставляем компонент для GA */}
            <LoadingPageWrapper queryKeys={["identities","statuses"]}>
                <EntityFullInfoIdentityMain />
            </LoadingPageWrapper>
    </CommonPageLayout> 
}
