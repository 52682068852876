import { validationToDamageTypes, validationToDamageTypesArray, validationToDate, validationToNumber, validationToNumbersArray, validationToRarityEGOTypes, validationToSinner, validationToSinTypes,  validationToString, validationToStringsArray, validationToTier } from "./validations";

const egoKeys = [
    { key: 'imgUrl' ,validation:validationToString},  { key: 'sinner' ,validation:validationToSinner},
    { key: 'nameEN' ,validation:validationToString},  { key: 'nameRU' ,validation:validationToString},
    { key: 'rarity',validation:validationToRarityEGOTypes},
    { key: 'season' ,validation:validationToStringsArray},  { key: 'egoResists' ,validation:validationToStringsArray},
    { key: 'sanity' ,validation:validationToNumbersArray}, { key: 'damage',validation:validationToNumbersArray },    { key: 'wrath' ,validation:validationToNumber},
    { key: 'lust' ,validation:validationToNumber},    { key: 'sloth' ,validation:validationToNumber},
    { key: 'glut' ,validation:validationToNumber},    { key: 'gloom' ,validation:validationToNumber},
    { key: 'pride' ,validation:validationToNumber},    { key: 'envy' ,validation:validationToNumber},
    { key: 'egoSin' ,validation:validationToSinTypes},  { key: 'dmgType',validation:validationToDamageTypesArray },
    { key: 'basicCoin',validation:validationToNumbersArray },  { key: 'growthPerCoin',validation:validationToNumbersArray },
    { key: 'maxCoinValue',validation:validationToNumbersArray }, { key: 'clashCoinValue',validation:validationToNumbersArray },
    { key: 'countCoin',validation:validationToNumbersArray },
    { key: 'weightCoin',validation:validationToNumbersArray },
    { key: 'nameSkillEN' ,validation:validationToStringsArray},
    { key: 'nameSkillRU' ,validation:validationToStringsArray},
    { key: 'descriptionCoinEN' ,validation:validationToString},
    { key: 'descriptionCoinRU' ,validation:validationToString},{ key: 'namePassiveEN',validation:validationToString },
    { key: 'namePassiveRU',validation:validationToString }, { key: 'descriptionPassiveEN' ,validation:validationToString},
    { key: 'descriptionPassiveRU' ,validation:validationToString},
    { key: 'egoTier',validation:validationToTier }, 
    { key: 'releaseDate',validation:validationToDate },
    { key: 'isNew',validation:validationToString }
    
];
export {egoKeys}

// export const normalizeKeys = (data: any): any => {
//     return {
//         imgUrl: data["imgUrl-id"],
//         sinner: data.sinner,
//         nameEN: data.nameEn,
//         nameRU: data.nameRu,
//         rarity: data.rarity,
//         season: data.season,
//         egoResists: data.egoResists,
//         sanity: data.sanity,
//         wrath: data.wrath,
//         lust: data.lust,
//         sloth: data.sloth,
//         glut: data.glut,
//         gloom: data.gloom,
//         pride: data.pride,
//         envy: data.envy,
//         egoSin: data.egoSin,
//         dmgType: data.dmgType,
//         basicCoin: data.basicCoin,
//         growthPerCoin: data.growthPerCoin,
//         maxCoinValue: data.maxCoinValue,
//         clashCoinValue: data.clashCoinValue,
//         countCoin: data.countCoin,
//         weightCoin: data.weightCoin,
//         nameSkillEN: data.nameSkillEn,
//         nameSkillRU: data.nameSkillRu,
//         descriptionCoinEN: data.descriptionCoinEn,
//         descriptionCoinRU: data.descriptionCoinRu,
//         namePassiveEN: data.namePassiveEn,
//         namePassiveRU: data.namePassiveRu,
//         descriptionPassiveEN: data.descriptionPassiveEn,
//         descriptionPassiveRU: data.descriptionPassiveRu,
//         egoTier: data.egoTier,
//         releaseDate: data.releaseDate,
//         isNew: data.isNew,
//     };
// };