export const statusesApiKey1 = "AIzaSyB0bjz4sw_A73INq0ewh3Z_7yiFoorM0iE"; 
export const statusesApiKey2 = "AIzaSyA_8-Q8fg3udmue7axdizvezLYIwlhuajI"; 

export const identitiesApiKey1 = 'AIzaSyDMOnVWmBl-KDTT-ZfQuu0dG_Hi2T-k9Po'; 
export const identitiesApiKey2 = 'AIzaSyBi4sBIbEPlYl8d-EAsgTC6s0Jeu16460U'; 
export const identitiesApiKey3 = 'AIzaSyDXLHMMhmCz_5061RtS0zfr6A-ylOap2Ls'; 

export const egoApiKey1 =  'AIzaSyBfHHdv_YAi77X2mvTSp2plvxJR-mSSIJc'; 
export const egoApiKey2 =  'AIzaSyDNsa87bdjPOVrxIbLqNhi8DWR4WkyrtAk'; 

export const md_giftsApiKey1 =  'AIzaSyBa3E1qYQne1SO-8SyNPHcYOCg0ccl1xEk'; 
export const md_giftsApiKey2 =  'AIzaSyBfBmgi9734sx7EIFFYQDsYkFP32oEgaQQ'; 

export const md_eventsApiKey1 =  'AIzaSyAyKsoQbDHSMmiXIuL_yy8V53cQi6iyAJY'; 
export const md_eventsApiKey2 =  'AIzaSyDT7C4tHS2OkjWFgkApm3lwelo4kfaQamo'; 
export const md_eventsApiKey3 =  'AIzaSyA474uQcrSzuSxEptMmrNA5z5AFP_PsRWg'; 

export const guidesApiKey1 =  'AIzaSyCuF5adgS1yZU6epJDBRoVaXxZ7UP-Pz3k'; 
export const guidesApiKey2 =  'AIzaSyAoWTzvqevmznVBL0oQMlCnQnnDnSSsd-o'; 

export const eventsListApiKey1 = 'AIzaSyBWn7NfAvAYyHU6pm8wo0X5r2mJOOtgOXk';
export const eventsListApiKey2 = 'AIzaSyDfmqsbglmIRlCjC5MGjCpZDvBXEGOJ4Kc';

export const newsListApiKey1 = 'AIzaSyB6ZylZWNy2AMeuqnuPG22extumPoWeWAU';
export const newsListApiKey2 = 'AIzaSyCDqUsvVY1w5FOpvR2OM4kKaSV-VBfYunk';

export const tagsApiKey1 = 'AIzaSyBp18wmxybC5c9urSeggXc66JyrdzvqGtk';
export const tagsApiKey2 = 'AIzaSyAEy5kYBRHCov4isdXQ_b4M3fkbdShmo5g';

export const bingoApiKey1 = 'AIzaSyAwPkIo6RgjBPMVxfVGwSTiRAxaNTecnts';