import { validationToDamageTypesArray, validationToDate, validationToGuardTypesArray, validationToNumber, validationToNumbersArray, validationToRarityIdentityTypes, validationToSinner, validationToSinTypesArray, validationToString, validationToStringsArray, validationToTier, validationToStringsArrayOrStandard, parseQuotedStringsToArray } from "./validations";
const idsKeys = [
    { key: 'imgUrl' ,validation:validationToString},  { key: 'sinner' ,validation:validationToSinner},
    { key: 'nameEN' ,validation:validationToString},  { key: 'nameRU' ,validation:validationToString},  
    { key: 'rarity',validation:validationToRarityIdentityTypes},
    { key: 'season' ,validation:validationToStringsArray},  { key: 'hp' ,validation:validationToNumber},
    { key: 'hpStun' ,validation:validationToNumbersArray}, { key: 'speed' ,validation:validationToString},
    { key: 'defense' ,validation:validationToNumbersArray},    { key: 'skillsSin' ,validation:validationToSinTypesArray},
    { key: 'skillsDmgType',validation:validationToDamageTypesArray },
    { key: 'guardSin' ,validation:validationToSinTypesArray},  
    { key: 'guardType',validation:validationToGuardTypesArray }, { key: 'basicCoin' ,validation:validationToNumbersArray},
    { key: 'growthPerCoin',validation:validationToNumbersArray },
    { key: 'maxCoinValue',validation:validationToNumbersArray},
    { key: 'fullMaxCoinValue',validation:validationToNumbersArray}, { key: 'damage' ,validation:validationToNumbersArray},
    { key: 'nameSkillEN',validation:parseQuotedStringsToArray },
    { key: 'nameSkillRU',validation:parseQuotedStringsToArray }, { key: 'countCoin' ,validation:validationToNumbersArray},
    { key: 'weightCoin',validation:validationToNumbersArray },{ key: 'descriptionCoinEN' ,validation:validationToString},
    { key: 'descriptionCoinRU' ,validation:validationToString},
    { key: 'idTier',validation:validationToTier },{ key: 'namePassiveEN',validation:parseQuotedStringsToArray },
    { key: 'namePassiveRU',validation:parseQuotedStringsToArray },
    { key: 'sinPassive1',validation:validationToSinTypesArray },{ key: 'countPassive1',validation:validationToNumbersArray },
    { key: 'sinPassive2',validation:validationToSinTypesArray },{ key: 'countPassive2',validation:validationToNumbersArray },
    { key: 'passive1Condition',validation:validationToString },{ key: 'passive2Condition',validation:validationToString },
    { key: 'descriptionPassive1EN',validation:validationToString },
    { key: 'descriptionPassive1RU',validation:validationToString }, 
    { key: 'descriptionPassive2EN',validation:validationToString },
    { key: 'descriptionPassive2RU',validation:validationToString },
    { key: 'slash',validation:validationToString },
    { key: 'pierce',validation:validationToString },{ key: 'blunt',validation:validationToString },
    { key: 'releaseDate',validation:validationToDate },{ key: 'minPossibleDmg',validation:validationToStringsArray },
    { key: 'maxPossibleDmg',validation:validationToStringsArray },
    { key: 'isNew',validation:validationToString },
    { key: 'sanityInfoEN',validation:validationToString }, { key: 'sanityInfoRU',validation:validationToString },
    { key: 'skillsOrder',validation:validationToStringsArrayOrStandard }

];
export {idsKeys}

// export const normalizeKeys = (data: any): any => {
//     return {
//         imgUrl: data["imgUrl-id"],
//         sinner: data.sinner,
//         nameEN: data.nameEn,
//         nameRU: data.nameRu,
//         rarity: data.rarity,
//         season: data.season,
//         hp: data.hp || 0,
//         hpStun: data.staggerThreshold,
//         speed: data.speed,
//         defense: data.defense,
//         skillsSin: data.skillsSin,
//         skillsDmgType: data.skillsDmgType,
//         guardSin: data.guardSin,
//         guardType: data.guardType,
//         basicCoin: data.basicCoin,
//         growthPerCoin: data.growthPerCoin,
//         maxCoinValue: data.maxCoinValue,
//         fullMaxCoinValue: data.fullMaxCoinValue,
//         damage: data.damage,
//         nameSkillEN: data.nameSkillEn,
//         nameSkillRU: data.nameSkillRu,
//         countCoin: data.countCoin,
//         weightCoin: data.weightCoin,
//         descriptionCoinEN: data.descriptionCoinEn,
//         descriptionCoinRU: data.descriptionCoinRu,
//         idTier: data.idTier,
//         namePassiveEN: data.namePassiveEn,
//         namePassiveRU: data.namePassiveRu,
//         sinPassive1: data.sinPassive1,
//         countPassive1: data.countPassive1,
//         sinPassive2: data.sinPassive2,
//         countPassive2: data.countPassive2,
//         passive1Condition: data.passive1Condition,
//         passive2Condition: data.passive2Condition,
//         descriptionPassive1EN: data.descriptionPassive1En,
//         descriptionPassive1RU: data.descriptionPassive1Ru,
//         descriptionPassive2EN: data.descriptionPassive2En,
//         descriptionPassive2RU: data.descriptionPassive2Ru,
//         slash: data.slash,
//         pierce: data.pierce,
//         blunt: data.blunt,
//         releaseDate: data.releaseDate,
//         minPossibleDmg: data.minimalDamage,
//         maxPossibleDmg: data.maximumDamage,
//         isNew: data.isNew,
//         sanityInfoEN: data.sanityInfoEn,
//         sanityInfoRU: data.sanityInfoRu,
//         skillsOrder: data.skillsOrder
//     };
// };