import React from "react";
import { useTranslation } from "react-i18next";
import { AboutGameInfo } from "../components/about-game-info/AboutGameInfo";
import { CommonPageLayout } from "./CommonPageLayout";
import { SEOHelmet } from "./SEOHelmet";
import Analytics from "../components/Analytics"; // Импортируем ваш компонент
import Sweepers from "../components/Sweepers";

export const AboutGamePage:React.FC = () => {
    const {t} = useTranslation();
    return <CommonPageLayout>
            <Sweepers/>
            <Analytics />  {/* Вставляем компонент для GA */}
            <SEOHelmet
                titleText={t("AboutGamePage.title") + " | Great Limbus Library"}
                descriptionText="Информация о игре"
                imageUrl="https://gll-fun.com/prewie/AboutGamePage.png" // Прямая ссылка на изображение
            />
            <AboutGameInfo/>
    </CommonPageLayout> 
}
