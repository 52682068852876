import React, { useState, useEffect } from 'react';

interface RunnerGroup {
  id: number;
  size: number;
  speed: number;
  bottom: number;
  initialDirection: -1 | 1;
  image: string;
}

const MovingImages = () => {
  // Конфигурация групп бегунов с разными картинками
  const groupsConfig: RunnerGroup[] = [
    { 
      id: 1, 
      size: 110, 
      speed: 0.3, 
      bottom: 0, 
      initialDirection: -1,
      image: '/images/sweppers1.png' 
    },
    { 
      id: 2, 
      size: 100, 
      speed: 0.5, 
      bottom: 0, 
      initialDirection: 1,
      image: '/images/sweppers2.png'
    },
    { 
      id: 3, 
      size: 80, 
      speed: 0.7, 
      bottom: 0, 
      initialDirection: -1,
      image: '/images/sweppers3.png'
    },
    { 
      id: 4, 
      size: 120, 
      speed: 0.4, 
      bottom: 0,  
      initialDirection: 1,
      image: '/images/sweppers4.png'
    },
    { 
      id: 5, 
      size: 120, 
      speed: 0.2, 
      bottom: 0,  
      initialDirection: 1,
      image: '/images/sweppers1.png'
    },
  ];

  const [runners, setRunners] = useState(() =>
    groupsConfig.map(group => ({
      id: group.id,
      position: group.initialDirection === -1 ? window.innerWidth : 0,
      direction: group.initialDirection,
      config: group
    }))
  );
  
  const [isActiveTime, setIsActiveTime] = useState(false);

  // Проверка текущего времени
  const checkTime = () => {
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    
    // Проверяем, находится ли текущее время в диапазоне 3:13 - 4:34
    const isActive = (hours === 3 && minutes >= 13) || 
                     (hours === 4 && minutes <= 34) ||
                     (hours > 3 && hours < 4);
    
    setIsActiveTime(isActive);
  };

  useEffect(() => {
    // Проверяем время сразу при монтировании
    checkTime();
    
    // Устанавливаем интервал для проверки времени каждую минуту
    const timeCheckInterval = setInterval(checkTime, 60000);
    
    return () => clearInterval(timeCheckInterval);
  }, []);

  useEffect(() => {
    if (!isActiveTime) return;

    let animationFrameId: number;
    let lastTime = 0;

    const animate = (time: number) => {
      const delta = time - lastTime || 16;
      lastTime = time;

      setRunners(prevRunners => 
        prevRunners.map(runner => {
          const { position, direction, config } = runner;
          const movement = direction * config.speed * delta;
          
          let newPosition = position + movement;
          let newDirection = direction;

          if (newPosition <= 0) {
            newPosition = 0;
            newDirection = 1;
          } else if (newPosition >= window.innerWidth) {
            newPosition = window.innerWidth;
            newDirection = -1;
          }

          return {
            ...runner,
            position: newPosition,
            direction: newDirection
          };
        })
      );

      animationFrameId = requestAnimationFrame(animate);
    };

    animationFrameId = requestAnimationFrame(animate);

    return () => cancelAnimationFrame(animationFrameId);
  }, [isActiveTime]);

  if (!isActiveTime) {
    return null; // Не рендерим ничего, если не активное время
  }

  return (
    <div>
      {runners.map(runner => {
        const groupConfig = groupsConfig.find(g => g.id === runner.id)!;
        return (
          <img
            key={runner.id}
            src={groupConfig.image}
            alt={`Runner group ${runner.id}`}
            style={{
              position: 'fixed',
              left: runner.position,
              bottom: `${groupConfig.bottom}px`,
              height: `${groupConfig.size}px`,
              transform: `translateX(-50%) scaleX(${runner.direction * -1})`,
              zIndex: 1000 + runner.id,
              transition: 'transform 0.2s ease',
            }}
          />
        );
      })}
    </div>
  );
};

export default MovingImages;