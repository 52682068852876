import React from 'react';
import { Helmet } from 'react-helmet-async';

interface ISEOHelmet {
    titleText: string;
    descriptionText: string;
    imageUrl?: string;  // Добавляем опциональный параметр для изображения
}

export const SEOHelmet: React.FC<ISEOHelmet> = ({
    titleText,
    descriptionText,
    imageUrl = "https://gll-fun.com/prewie/logo.png", // Укажите дефолтное изображение
}) => {
    return (
        <Helmet>
            {/* Базовые мета-теги */}
            <title>{titleText}</title>
            <meta name="description" content={descriptionText} />

            {/* Open Graph (для соцсетей) */}
            <meta property="og:title" content={titleText} />
            <meta property="og:description" content={descriptionText} />
            <meta property="og:image" content={imageUrl} />
            <meta property="og:url" content="https://gll-fun.com/" />
            <meta property="og:type" content="website" />

            {/* Twitter Card (опционально) */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={titleText} />
            <meta name="twitter:description" content={descriptionText} />
            <meta name="twitter:image" content={imageUrl} />
        </Helmet>
    );
};